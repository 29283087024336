


jQuery(document).ready(function () {

    var Flickity = require('flickity');
    const footerSlider = jQuery('.footer__carousel'),
        footerSlides = jQuery('.footer__carousel .footer__logo')

    if (footerSlider.length > 0 && footerSlides.length > 1) {


         var flky = new Flickity( '.footer__carousel', {
            autoPlay: true,
            prevNextButtons: true,
            cellSelector: '.footer__logo',
            pageDots     : false,
            lazyLoad     : true,
            resize       : true,
            groupCells   : true,
            draggable    : true,
            wrapAround   : true,
            setGallerySize: false,
            cellAlign    : 'left',
            contain: true,
            ImagesLoaded: true,
            arrowShape   : {
                        x0: 30,
                        x1: 55, y1: 30,
                        x2: 65, y2: 30,
                        x3: 40
                    },

         });
    }

})
