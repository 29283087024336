import masonry from 'masonry-layout/dist/masonry.pkgd.min.js'
import { WOW } from 'wowjs/dist/wow.min.js'
import 'jquery-lazyload'
// import 'fancybox/dist/js/jquery.fancybox.pack.js'
import {without, throttle, debounce} from 'lodash'
import whatInput from 'what-input'
import './program-strip';

import { Fancybox } from "@fancyapps/ui";


(function( $ ) {

	"use strict";
	$(window).load(function() {
		$('figure .lazy').lazyload({
			placeholder: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKAQMAAAC3/F3+AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABlBMVEWMjIz///8fMh9uAAAAAWJLR0QB/wIt3gAAAAlwSFlzAAALEgAACxIB0t1+/AAAAAtJREFUCNdjYMAHAAAeAAFuhUcyAAAAAElFTkSuQmCC'
		});
	});

	// var grid_col = 200;
	var grid_gutter = 60;
	// var device = 'desktop';
	// var enable_arrows = true;

	// if ( $('body').hasClass('mobile') ) {
	// 	grid_col = 300;
	// 	grid_gutter = 50;
	// 	device = 'mobile';
	// 	enable_arrows = false;
	// }
	//
	// if (jQuery(window).width() < 1340 && jQuery(window).width() > 1024) {
	// 	grid_col = 280;
	// 	grid_gutter = 24;
	// 	device = 'mobile';
	// 	enable_arrows = false;
	// }

	Fancybox.bind("[data-fancybox]", {
		// Your custom options
	});

	if(jQuery(window).width() >= 768 && jQuery(window).width() <1024){
		// grid_gutter = 100
	}


		jQuery('.card__holder').masonry({
			itemSelector: '.card',
			columnWidth: '.grid-sizer',
			percentPosition: true,
			gutter: grid_gutter
			// percentPosition: false,
			// gutter: grid_gutter,
			// transitionDuration: 0
		});
		//
		var wow = new WOW( {
			boxClass:     'wow',
			animateClass: 'animated',
			offset:       0,
			mobile:       true,
			live:         false
		} );


		setTimeout(() => {
		  $('body').addClass('loaded');
			// if ( device == 'desktop' ) {
				wow.init();
			// }
		}, "1000")



	$('.article').fancybox({
		helpers:  {
			title : {
			    type : 'outside'
			}
		},
		nextEffect  : 'none',
		prevEffect  : 'none',
		openEffect	: 'none',
		closeEffect	: 'none',
		padding     : 0,
		afterShow   : function() {

			$(window).trigger('resize');

		}
	});

	$('.video').fancybox({
		helpers:  {
			title : {
			    type : 'outside'
			}
		},
		nextEffect  : 'none',
		prevEffect  : 'none',
		openEffect	: 'none',
		closeEffect	: 'none',
		padding     : 0
	});

	$('.qoute').fancybox({
		helpers:  {
			title : {
			    type : 'outside'
			}
		},
		nextEffect  : 'none',
		prevEffect  : 'none',
		openEffect	: 'none',
		closeEffect	: 'none',
		padding     : 0,
		afterShow   : function() {

			$(window).trigger('resize');

		}
	});


}(jQuery));
